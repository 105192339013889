.Checkout {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.Checkout .Payment {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    margin-top: 80px;
}

.Checkout .Payment .PlainSelected {
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 20px;
    margin: 10px;
    background-color: var(--White);
}
.Checkout .Payment .PlainSelected h3 {
    font-weight: bold;
}
.Checkout .Payment .PlainSelected .plain {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}


.Checkout .Payment .PlainSelected .plain h4 {
    font-weight: bold;
}
.Checkout .Payment .PlainSelected .plain h4 span {
    font-weight: 900;
}

.Checkout .Payment .PlainSelected a {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--White);
    border: 1px solid var(--Primary);
    padding: 10px;
    color: var(--Primary);
    border-radius: 3px;
}

.Checkout .Payment .PlainSelected .text {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin: 5px 0;
    padding: 10px 5px;
    border: none;
    border-top: 1px solid var(--Border);
    border-bottom: 1px solid var(--Border);
}

.Checkout .Payment .PlainSelected .text p {
    font-size: 12px;
    font-weight: 700;
    margin: 2px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    color: var(--Description);
}


.Checkout .Payment .PlainSelected .text svg {
    color: var(--Aproved);
    font-size: 16px;
    margin-right: 5px;
}

.Checkout .Payment .PlainSelected .pricePlain {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
}
.Checkout .Payment .PlainSelected .pricePlain2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0px 30px 0px;
}
.Checkout .Payment .PlainSelected .pricePlain2 .addUsers {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 0px;
}

.Checkout .Payment .PlainSelected .pricePlain2 .addUsers button {
    width: 35px;
    height: 25px;
    color: var(--White);
    background-color: var(--Primary);
}

.Checkout .Payment .PlainSelected .pricePlain2 p {
    font-weight: 700;
}
.Checkout .Payment .PlainSelected .pricePlain p {
    font-weight: 700;
}
.Checkout .Payment .PlainSelected .pricePlain h4 {
    font-weight: 700;
    margin-left: 10px;
}
.Checkout .Payment .PlainSelected .pricePlain h3 {
    font-weight: 700;
    margin-left: 10px;
}

.Checkout .Payment .PaymentPlayn {
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 20px;
    margin: 10px;
    color: var(--Description);
    background-color: var(--White);
}
.Checkout .Payment .PaymentPlayn .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0;
}

.Checkout .Payment .PaymentPlayn h3 {
    font-weight: 700;
    margin-bottom: 10px;
}
.Checkout .Payment .PaymentPlayn p {
    font-weight: 700;
    color: var(--Paragraph);
}

.Checkout .Payment .PaymentPlayn .Method {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin: 20px 0;
    padding: 20px 5px;
    border: none;
    border-top: 1px solid var(--Border);
    border-bottom: 1px solid var(--Border);
}
.Checkout .Payment .PaymentPlayn .Method h4 {
    font-weight: 700;
}
.Checkout .Payment .PaymentPlayn .Method p {
    font-weight: 700;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--BorderInput);
    padding: 10px;
    color: var(--Paragraph);
    border-radius: 3px;
}
.Checkout .Payment .PaymentPlayn .Method img {
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    width: 250px;
    margin: 10px 0 20px 0;
}

.Checkout .Payment .PaymentPlayn a {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    padding: 10px;
    color: var(--White);
    border-radius: 3px;
}
.Checkout .Payment .PaymentPlayn button {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    padding: 10px;
    color: var(--White);
    border-radius: 3px;
    border: none;
}
.Checkout .Payment .PaymentPlayn .btn-pix {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #2EC3A3;
    padding: 10px;
    color: var(--White);
    border-radius: 3px;
    border: none;
}

.react-modal-content .content-modal-home-Search .itensModalHome-Search .infos {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-itens: center;
    width: 90%;
    padding: 10px;
    margin: 5px 10px;
    border-radius: 6px;
    background-color: var(--Primary);
    color: var(--White);
}
.react-modal-content .content-modal-home-Search .itensModalHome-Search .infos2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-itens: center;
    width: 90%;
    padding: 10px;
    margin: 5px 10px 20px 10px;
    border-radius: 6px;
    background-color: var(--Aproved);
    color: var(--White);
}

.react-modal-content .content-modal-home-Search .itensModalHome-Search h6 {
    font-weight: 700;
    color: var(--Gray);
    margin: 5px 0;
}



.react-modal-content .content-modal-home-Search .itensModalHome-Search button {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    padding: 10px;
    color: var(--White);
    border-radius: 3px;
    border: none;
    margin-top: 20px;
}

@media (max-width: 750px) {
    .Checkout .Payment {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 60px;
    }
    
    .Checkout .Payment .PlainSelected {
        width: 100%;
    }

    
.Checkout .Payment .PaymentPlayn {
    width: 100%;
}

}