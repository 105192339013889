.btnImages {
    background-color: var(--Primary);
    color: var(--White);
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 20px;
}

.btnImages svg {
    font-size: 16px;
    margin-right: 3px;
}