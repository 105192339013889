.MyClientsList {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.MyClientsList .aside {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: 170px;
    padding-top: 80px;
}

.MyClientsList .aside .textTop {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px;
}

.MyClientsList .aside .textTop h3 {
    font-weight: bold;
    text-align: center;
    color: var(--Paragraph);
    margin: 0px;
}

.MyClientsList .aside .textHome {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


.MyClientsList .aside .textHome h3 {
    font-weight: bold;
    text-align: center;
    color: var(--Paragraph);
}


.MyClientsList .aside .infoData {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 15px;
background-color: var(--White);
box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
border-radius: 6px;
}

.MyClientsList .aside .infoData .textInfo{
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--Border);
  border-radius: 6px;
  margin: 5px;
}

.MyClientsList .aside .infoData .textInfo h5 {
  font-weight: 600;
  text-align: center;
  color: var(--Gray);
}
.MyClientsList .aside .infoData .textInfo h5 span {
  font-weight: 900;
  color: var(--Primary);
}

.MyClientsList .informationsClients {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    padding: 10px 15px;
    background-color: var(--White);
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
}

.MyClientsList .informationsClients .clientListAdm{
    width: 100%;
    border-radius:6px;
    background-color: var(--BorderHover);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-bottom: 1px solid var(--Border);
}
.MyClientsList .informationsClients .clientListAdm .image{
    border-radius: 6px;
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin: 3px;
}
.MyClientsList .informationsClients .clientListAdm .image img{
    border-radius: 6px;
    width: 60px;
    height: 60px;
    object-fit: cover;
}

.MyClientsList .informationsClients .clientListAdm .textclientListAdm{

    background-color: var(--BorderInput);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 5px 10px;
    text-align:left;
    width: 100%;
    background-color: var(--BorderHover);
}
.MyClientsList .informationsClients .clientListAdm .textclientListAdm .textDataclientListAdm{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    text-align:left;
    width: 100%;
    height: 100%;
}
.MyClientsList .informationsClients .clientListAdm .textclientListAdm .textDataclientListAdm h4 {
    color: var(--Primary);
    font-weight: 700;
    margin: 0;
    text-align:left;
  }

  .MyClientsList .informationsClients .clientListAdm .textclientListAdm .textDataclientListAdm h5 {
    color: var(--Gray);
    margin: 0 0 0 0;
    text-align:left;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    font-weight: 500;
  }
  .MyClientsList .informationsClients .clientListAdm .textclientListAdm .textDataclientListAdm h5 svg {
    margin: 0 3px 0 0px;
  }
  /* .MyClientsList .informationsClients .clientListAdm .textclientListAdm .textDataclientListAdm h6 {
    color: var(--Gray);
    margin: 5px 10px 0 10px;
    text-align:left;
  } */
  /* .MyClientsList .informationsClients .clientListAdm .textclientListAdm h5 {
    margin: 2px 0 0 0;
    text-align:center;
    padding: 3px 5px;
    background-color: var(--Aproved);
    color: var(--White);
    border-radius: 3px;
    font-size: 12px;
  } */
  .MyClientsList .informationsClients .clientListAdm .infosContactData {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: 10px 0;
    width: 100%;
    flex-wrap: wrap;
  }
  .MyClientsList .informationsClients .clientListAdm .infosContactData .infoUnicData {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: 10px;
    margin: 5px;
    border-radius: 6px;
    border: 1px solid var(--Border);
  }

  .MyClientsList .informationsClients .clientListAdm .infosContactData .infoUnicData svg {
    color: var(--Gray);
    margin-right: 5px;
  }
  .MyClientsList .informationsClients .clientListAdm .infosContactData .infoUnicData  h5 {
    font-weight: 600;
    color: var(--Gray);
  }

  .MyClientsList .informationsClients .clientListAdm .infosContactData .infoUnicData:hover {
    background-color: var(--BorderInput2);
  }

  .MyClientsList .informationsClients .clientListAdm .buttonsClients{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 10px;
    border-left: 1px solid var(--Border);
    height: 100%;
}


  .MyClientsList .informationsClients .clientListAdm .buttonsClients .btnControl {
  width: 30px;
  height: 30px;
  padding: 8px;
  border: none;
  border-radius: 20px;
  background-color: var(--BorderInput);
  color: var(--Gray2);
  margin: 5px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
  .MyClientsList .informationsClients .clientListAdm .buttonsClients .btnControl svg {
  margin: 0px;
}

.MyClientsList .informationsClients .clientListAdm .buttonsClients .btnControl:hover {
  background-color: var(--Primary);
  color: var(--White);
}


.MyClientsList .aside .search {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 10px;
  width: 90%;
  padding: 10px;
  background-color: var(--White);
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}
.MyClientsList .aside .search .selection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
}


.MyClientsList .aside .search input {
  width: 50%;
  height: 40px;
  padding: 10px 20px;
  border: 1px solid var(--Border);
  border-radius: 20px;
  margin: 2px;
}
.MyClientsList .aside .search .selection select {
  width: 100%;
  height: 40px;
  padding: 10px 20px;
  border: 1px solid var(--Border);
  border-radius: 20px;
  margin: 2px;
}


@media(max-width: 850px) {
  .MyClientsList .aside .search {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
  }
  
  
  .MyClientsList .aside .search input {
    width: 100%;
  }


@media (max-width: 700px) {
    .MyClientsList .informationsClients {
      padding: 10px;
    }
    .MyClientsList .aside {
        padding-left: 0px;
    }



    @media (max-width: 570px) {
          .MyClientsList .informationsClients .clientListAdm{
            width: 100%;
            margin:20px 5px;

            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
        }

            .MyClientsList .informationsClients .clientListAdm .image{
           display: none;
        }

        .MyClientsList .informationsClients .clientListAdm .infosContactData .infoUnicData {
          padding: 5px;
        }
      

        .MyClientsList .informationsClients .clientListAdm .buttonsClients{
          justify-content: space-evenly;
          flex-direction: row;
          width: 100%;
          border-left:none;
          border-top: 1px solid var(--Border);
          padding: 5px 10px 5px 5px;
      }

        .MyClientsList .informationsClients .clientListAdm .buttonsClients .linkEdit {
        width: 100%;
      }
        .MyClientsList .informationsClients .clientListAdm .buttonsClients .delete {
        width: 100%;
      }
        .MyClientsList .informationsClients .clientListAdm .buttonsClients .notView {
        width: 100%;
      }


      .MyClientsList .aside .search {
        width: 90%;
        margin:20px 5px;
      }

      .MyClientsList .aside .search .selection {
        flex-direction: column;
        width: 100%;
      }

      .MyClientsList .aside .infoData .textInfo {
        padding: 5px 10px;
        margin: 3px;
      }
      .MyClientsList .aside .infoData .textInfo h5 {
        font-size: 12px;
      }
      
    }
}
}
