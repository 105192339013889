.SupportAdm {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.SupportAdm .aside {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: 170px;
    padding-top: 80px;
}
.SupportAdm .aside h3 {
    margin: 20px 0;
    font-weight: bold;
    text-align: center;
}
.SupportAdm .informations {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
border-radius: 20px;
background-color: var(--White);
}

.SupportAdm .informations .TextInfosSupport {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    border-radius: 20px;
    padding: 5px;
    margin: 10px 20px;
    
}

.SupportAdm .informations .TextInfosSupport h4{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--Paragraph);
    font-weight: 600;
}

.SupportAdm .informations .TextInfosSupport h4 svg {
    margin-right: 5px;
}

.SupportAdm .informations .buttonsSupport {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 100px;
    border-radius: 20px;
    margin: 10px;
border-radius: 20px;
}

.SupportAdm .informations .buttonsSupport h2 {
    color: var(--Primary);
}

.SupportAdm .informations .buttonsSupport a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: var(--White);
    color: var(--Paragraph);
    border: 1px solid var(--Border);
    border-radius: 20px;
    padding: 10px 20px;
    width: 95%;
}

.SupportAdm .informations .buttonsSupport a svg {
    margin-right: 5px;
    color: var(--Primary);
}

.SupportAdm .informations .buttonsSupport a:hover {
    background-color: var(--BorderInput2);
}


@media (max-width: 700px) {
    .SupportAdm .informations {
        margin-bottom: 100px;
    }
    .SupportAdm .aside {
        padding-left: 0px;
    }
}