.CancelAccount {
    width: 100%;
    height: 100%;
    background-color: var(--BorderHover);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
}

.CancelAccount h2 {
    text-align: center;
    color: var(--Primary);
    font-weight: 900;
    margin: 10px;
}
.CancelAccount img {
    width: 250px;
    margin: 10px;
}

.CancelAccount .coming {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 10px;
}
.CancelAccount h4 {
    text-align: center;
    color: var(--Gray);
    font-weight: 700;
    margin-bottom: 10px;
}
.CancelAccount h6 {
    text-align: center;
    color: var(--White);
    font-weight: 700;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 20px;
    background-color: var(--Primary);
    font-size: 12px;
    width: 450px;
}
.CancelAccount .coming h5 {
    text-align: center;
    color: var(--Gray);
    font-weight: 700;
    margin-bottom: 10px;
}
.CancelAccount .coming h4 {
    text-align: center;
    color: var(--Gray);
    font-weight: 700;
    margin-bottom: 20px;
}
.CancelAccount .coming h4 svg {
    margin-right: 3px;
    margin-bottom: -2px;
    margin-top: 0px;
}
.CancelAccount .logo {
    width: 150px;
    margin: 20px;
}
.CancelAccount .logo img {
    width: 100%;
}
.CancelAccount .selections {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.CancelAccount .selections select {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-decoration: none;
    border: none;
    background-color: var(--BorderInput);
    color: var(--Paragraph);
    padding: 10px 20px;
    border-radius: 20px;
    margin: 5px;
    width: 100%;
    font-size: 14px;
}

.CancelAccount .selections textarea {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-decoration: none;
    border: none;
    background-color: var(--BorderInput);
    color: var(--Paragraph);
    padding: 10px 20px;
    border-radius: 20px;
    margin: 5px;
    width: 100%;
    font-size: 14px;
    resize: none;
}


.CancelAccount .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.CancelAccount .buttons a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-decoration: none;
    border: none;
    background-color: var(--Primary);
    padding: 10px 20px;
    border-radius: 20px;
    color: var(--White);
    margin: 5px;
    width: 200px;
    font-size: 14px;
}
.CancelAccount .buttons button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-decoration: none;
    border: none;
    background-color: var(--Primary);
    padding: 10px 20px;
    border-radius: 20px;
    color: var(--White);
    margin: 5px;
    width: 200px;
    font-size: 14px;
}

.CancelAccount .buttons .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-decoration: none;
    border: none;
    background-color: var(--BorderInput);
    color: var(--Primary);
    padding: 10px 20px;
    border-radius: 20px;
    margin: 5px;
    width: 200px;
    font-size: 14px;
}
.CancelAccount .buttons .logout {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-decoration: none;
    border: none;
    background-color: var(--BorderInput2);
    padding: 10px 20px;
    border-radius: 20px;
    color: var(--Primary);
    margin: 5px;
    width: 200px;
    font-size: 14px;
}



@media (max-width: 600px) {

    .CancelAccount h2 {
        font-size: 20px;
    }

    .CancelAccount h6 {
        width: 95%;
    }

    .CancelAccount img {
        width: 150px;
        margin: 20px 10px;
    }

    .CancelAccount .image {
        width: 261px;
        height: 164px;
        margin-top: 63px;
        margin-left: 1px;
    }


    .CancelAccount .coming {
        width: 90%;
    }
}