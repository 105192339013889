.link {
    border: none;
    border-radius: 20px;
    background-color: none;
    color: var(--Primary);
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    background-color: var(--Background);
  }

  .btnNewClient {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 20px;
    background-color: var(--Aproved);
    color: var(--White);
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
    .btnNewClient svg {
    margin: 0px;
  }
  
  .btnNewClient:hover {
    background-color: var(--Primary);
    color: var(--White);
  }

  

  .content-modal-team {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;    
    overflow:auto;
    max-height: 600px;  
}

  .content-modal-team::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }
  
    .content-modal-team::-webkit-scrollbar-track {
    background: var(--BorderHover);        /* color of the tracking area */
    border-radius: 20px;
  }
  
    .content-modal-team::-webkit-scrollbar-thumb {
    background-color: var(--Border);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid var(--BorderHover);  /* creates padding around scroll thumb */
  }


.content-modal-team .itensModal-team {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
    padding: 10px;

}

.content-modal-team .itensModal-team .form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
}

.content-modal-team .itensModal-team h3 {
    margin: 10px;
    color: var(--Primary);
    font-weight: 600;
}

.content-modal-team .itensModal-team .form .ProcessData {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    padding: 10px; 
}

.content-modal-team .itensModal-team .form .ProcessData .dataUser {
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
    margin: 5px 3px;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.content-modal-team .itensModal-team .form .ProcessData .dataUser  h5 {
    color: var(--Paragraph);
    margin: 0px 0px 3px 3px;
    font-weight: 600;
}

.content-modal-team .itensModal-team .form .ProcessData .searchData {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.content-modal-team .itensModal-team .form .ProcessData .dataUser input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-radius: 20px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    padding: 10px;
    margin: 3px 0;
}

.content-modal-team .itensModal-team .form .ProcessData .dataUser .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 20px;
    border: 1px solid var(--Primary);
    background-color: var(--PrimaryHover);
    margin: 0 0 0 5px;
    padding: 9px;
    color: var(--White);
}

.content-modal-team .itensModal-team .form .ProcessData .dataUser .userInformations {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 20px;
}

.content-modal-team .itensModal-team .form .ProcessData .dataUser .userInformations h4 {
    margin: 2px 3px;
    color: var(--Primary);
    font-weight: 600;
}
.content-modal-team .itensModal-team .form .ProcessData .dataUser .userInformations h5 {
    font-weight: 400;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    color: var(--Gray);
}

.content-modal-team .itensModal-team .form .ProcessData .dataUser .userInformations h5 svg {
    margin-right: 5px;
}




.content-modal-team .itensModal-team .form .ProcessData .dataProperty {
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
    margin: 5px 3px;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.content-modal-team .itensModal-team .form .ProcessData .dataProperty   h5 {
    color: var(--Paragraph);
    margin: 0px 0px 3px 3px;
    font-weight: 600;
}

.content-modal-team .itensModal-team .form .ProcessData .dataProperty .image {
    width: 100%;
    height: 50px;
    border-radius: 20px 20px 0 0;
    object-fit: cover;
    margin: 5px 0;
}

.content-modal-team .itensModal-team .form .ProcessData .dataProperty .image img {
    width: 100%;
    height: 50px;
    border-radius: 20px 20px 0 0;
    object-fit: cover;
}

.content-modal-team .itensModal-team .form .ProcessData .dataProperty .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 20px;
    border: 1px solid var(--Primary);
    background-color: var(--PrimaryHover);
    margin: 5px;
    padding: 9px;
    color: var(--White);
}

.content-modal-team .itensModal-team .form .ProcessData .dataProperty .userInformations {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 20px;
}

.content-modal-team .itensModal-team .form .ProcessData .dataProperty .userInformations h4 {
    margin: 2px 3px;
    color: var(--Primary);
    font-weight: 600;
}
.content-modal-team .itensModal-team .form .ProcessData .dataProperty .userInformations h5 {
    font-weight: 400;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    color: var(--Gray);
}

.content-modal-team .itensModal-team .form .ProcessData .dataProperty .userInformations h5 svg {
    margin-right: 5px;
}



.content-modal-team .itensModal-team .form .DataInputs {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    padding: 10px 5px 15px 5px;
    border: 1px solid var(--Border);
    margin: 10px 0;
    border-radius: 20px;
    flex-wrap: wrap;
}

.content-modal-team .itensModal-team .form .DataInputsSwitch {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    padding: 10px 5px 15px 5px;
    border: 1px solid var(--Border);
    margin: 10px 0;
    border-radius: 20px;
    flex-wrap: wrap;
}
.content-modal-team .itensModal-team .form .DataInputs .Avatar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px;
}

.content-modal-team .itensModal-team .form .DataInputs .Avatar h5 {
    color: var(--Paragraph);
    font-weight: 600;
}

.content-modal-team .itensModal-team .form .DataInputs .Avatar .label-avatar{
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    margin: 5px;
}

.content-modal-team .itensModal-team .form .DataInputs .Avatar .label-avatar input {
    display: none;
}

.content-modal-team .itensModal-team .form .DataInputs .Avatar .label-avatar span {
    z-index: 99;
    position: absolute;
    opacity: 0.7;
    transition: all .5s;
}

.content-modal-team .itensModal-team .form .DataInputs .Avatar .label-avatar span:hover{
    opacity: 1;
    transform: scale(1.4);
}

.content-modal-team .itensModal-team .form .DataInputs .Avatar .label-avatar img{
    margin-bottom: 1em;
    border-radius: 20px;
    object-fit: cover;
    width: 100px;
    height: 100px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}

.content-modal-team .itensModal-team .form .DataInputs .dataInputUnic {
    width: 33%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0 5px;
}

.content-modal-team .itensModal-team .form .DataInputs .dataInputUnic h5 {
    color: var(--Paragraph);
    margin: 10px 10px 5px 10px;
    font-weight: 600;
}
.content-modal-team .itensModal-team .form .DataInputsSwitch .dataInputSwitch {
    width: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 5px;
    flex-wrap: wrap;
}

.content-modal-team .itensModal-team .form .DataInputsSwitch .dataInputSwitch h5 {
    color: var(--Paragraph);
    margin: 10px 10px 5px 10px;
    font-weight: 600;
}

.content-modal-team .itensModal-team .form .DataInputs .searchInputs {
    width: 100%;
    display: flex;
    align-items:center;
    justify-content:center;
    flex-direction: row;
}

.content-modal-team .itensModal-team .form .DataInputs .searchInputs input {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-radius: 20px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    padding: 10px 20px;
    margin: 5px 0;
}
.content-modal-team .itensModal-team .form .DataInputs .searchInputs button {
    background-color: var(--Primary);
    color: var(--White);
    border-radius: 20px;
}

.content-modal-team .itensModal-team .form .DataInputs .dataInputUnic .doubleInputs {
    width: 100%;
    display: flex;
    align-items:center;
    justify-content:center;
    flex-direction: row;
}

.content-modal-team .itensModal-team .form .DataInputs .dataInputUnic input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-radius: 20px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    padding: 10px 20px;
}
.content-modal-team .itensModal-team .form .DataInputs .dataInputUnic input::placeholder {
    color: var(--Gray);
}
.content-modal-team .itensModal-team .form .DataInputs .dataInputUnic select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-radius: 20px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    padding: 10px 20px;
}

.content-modal-team .itensModal-team .form .DataInputs .dataInputUnic .doubleInputs {
    width: 100%;
    display: flex;
    align-items:center;
    justify-content:center;
    flex-direction: row;
}

.content-modal-team .itensModal-team .form .DataInputs .dataInputUnic .doubleInputs  input {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-radius: 20px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    padding: 10px;
    margin: 3px;
}

.content-modal-team .itensModal-team .form .DataInputs .dataInputUnic .doubleInputs select {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-radius: 20px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    padding: 10px 20px;
}

.content-modal-team .itensModal-team .form .ButtonsForm {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 20px 0;
}
.content-modal-team .itensModal-team .form .send {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 20px;
    border: 1px solid var(--Primary);
    background-color: var(--PrimaryHover);
    margin: 5px;
    padding: 10px 20px;
    color: var(--White);
}
.content-modal-team .itensModal-team .form .cancel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 20px;
    border: 1px solid var(--Primary);
    background-color: var(--White);
    margin: 5px;
    padding: 10px 20px;
    color: var(--Primary);
}

@media (max-width: 570px) {
    
    .New {
        width: 100%;
    }
    @media (max-width: 550px) {


    .content-modal-team .itensModal-team .form .ProcessData {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 10px; 
    }
    
    .content-modal-team .itensModal-team .form .ProcessData .dataUser {
        width: 100%;
    }
    
    
    .content-modal-team .itensModal-team .form .ProcessData .dataProperty {
        width: 100%;
    }
    

    .content-modal-team .itensModal-team .form .DataInputs {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        padding: 5px;
    }
    .content-modal-team .itensModal-team .form .DataInputsSwitch {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        padding: 5px;
        flex-wrap: wrap;
    }

    .content-modal-team .itensModal-team .form .DataInputs .dataInputUnic {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding: 5px;
    }
    
    
}
}