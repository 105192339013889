.UpgradePlain {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.UpgradePlain .aside {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding:30px;
}



.UpgradePlain .aside .solicitation{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
}
.UpgradePlain .aside .solicitation h2{
    color: var(--Primary);
    font-weight: 800;
    text-align: center;
}
.UpgradePlain .aside .solicitation h4{
    color: var(--Gray);
    font-weight: 600;
    text-align: center;
    margin: 10px 5px;
}

.UpgradePlain .aside .solicitation .logo {
    width: 80px;
}
.UpgradePlain .aside .solicitation .logo img {
    width: 100%;
    margin: 5px 0px;
}

.UpgradePlain .aside .solicitation .blockCenter {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    padding: 5px;
    margin: 5px;
}




.UpgradePlain .aside .solicitation .blockCenter .image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    margin: 10px;
}

.UpgradePlain .aside .solicitation .blockCenter .image img{
    width: 350px;
    margin: 20px 0;
}

.UpgradePlain .aside .solicitation .blockCenter .image .btnSolicitation {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    color: var(--White);
    margin: 5px;
    border-radius: 20px;
    width: 250px;
    }

    .UpgradePlain .aside .solicitation .blockCenter .image .btnClose {
        padding: 10px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: var(--Gray);
        color: var(--White);
        margin: 5px;
        border-radius: 20px;
        width: 250px;
        }
    


.UpgradePlain .aside .solicitation .blockCenter .myPlain {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px;
    margin: 10px;
}


.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px;
    margin: 10px auto;
    border-radius: 20px;
    background-color: var(--White);
    color: var(--Description);
    width: 300px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}

.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain h3 {
    font-weight: 800;
}

.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain p{
    font-weight: 500;
    color: var(--Description);
    margin: 5px 0;
}

.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain .featured {
    width: 290px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    position: absolute;
    margin-top: -10px;
    margin-left: -10px;
}

.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain .featured h5 {
    background-color: var(--Primary);
    color: var(--White);
    padding: 10px 15px;
    border-radius: 6px 5px 0  6px;
    font-weight: 700;
}



.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain .title {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: row; 
}

.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain .title h1 {
    font-weight: 700;
    color: var(--Primary);
}
.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain .title h3 {
    font-weight: 900;
    color: var(--Primary);
}

.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain .title h4 {
    font-weight: 700;
    margin-left: 5px;
    color: var(--Primary);
    margin-right: 5px;
}
.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain .title2 {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: row; 
}

.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain .title2 h1 {
    font-weight: 700;
    color: var(--Gray);
    text-decoration: line-through;
    font-size: 20px;
}

.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain .title2 h6 {
    font-weight: 700;
    margin-left: 2px;
    color: var(--Gray);
    margin-right: 5px;
}

.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain .text {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin: 5px 0;
    text-align: left;
}


.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain .text p {
    font-size: 12px;
    font-weight: 600;
    margin: 2px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    text-align: left;
    width: 100%;
    padding: 3px 10px;
    border: none;
    border-bottom: 1px solid var(--Border);
}

.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain .aditionalUser {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin: 5px 0;
    text-align: left;
}

.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain .aditionalUser h5 {
    font-weight: 600;
    margin: 2px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    text-align: left;
    width: 100%;
    padding: 3px 10px;
    border: none;
}

.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain .aditionalUser h5 span {
    font-weight: 600;
    margin-left: 5px;
    color: var(--Gray2);
}
.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain .aditionalUser h5 svg {
    margin-right: 5px;
    color: var(--Gray2);
    font-size: 16px;
}



.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain .text svg {
    color: var(--AprovedHover);
    font-size: 16px;
    margin-right: 5px;
}

.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain a {
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: var(--White);
    font-weight: 700;
    margin: 10px 0 2px 0;
}
.UpgradePlain .aside .solicitation .blockCenter .myPlain .plain button {
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Button);
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    color: var(--White);
    font-weight: 700;
    margin: 10px 0 5px 0;
}



.UpgradePlain .aside .solicitation .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    flex-wrap: wrap;
    }
.UpgradePlain .aside .solicitation .buttons .btnSolicitation {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    color: var(--White);
    margin: 5px;
    border-radius: 20px;
    width: 200px;
    }
.UpgradePlain .aside .solicitation .buttons .btnClose {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Gray);
    color: var(--White);
    margin: 5px;
    border-radius: 20px;
    width: 200px;
    }

@media (max-width: 950px) {
    .UpgradePlain .aside .form {
        width: 95%;
    }

    @media (max-width: 750px) {

        .UpgradePlain .aside .solicitation .blockCenter {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column-reverse;
            padding: 5px;
            margin: 5px;
        }
        
        
    @media (max-width: 700px) {
        .UpgradePlain .aside {
            padding-left: 0px;
            padding-bottom: 80px;
        }

        @media (max-width: 650px) {
            .UpgradePlain .aside .form {
                width: 95%;
            }

            .UpgradePlain .aside .form .data {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
            }

            .UpgradePlain .aside .solicitation img{
                width: 90%;
                margin: 20px 0;
            }
            

            
        }
        }
    }
}


