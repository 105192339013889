
.buttonsAccount {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0;
    margin: 0 0 10px 0;
    border-bottom: 1px solid var(--Border);
    flex-wrap: wrap;
    }
.buttonsAccount .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    }

    .buttonsAccount .buttons a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        background-color: var(--BorderInput2);
        color: var(--Paragraph);
        border-radius: 20px;
       border: none;
       margin: 5px;
       font-size: 12px;
       text-decoration: none;
    }

    .buttonsAccount .buttons a svg {
        margin-right: 5px;
    }

    .buttonsAccount .buttons .cancel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        background-color: var(--Primary);
        color: var(--White);
        border-radius: 20px;
       border: none;
       margin: 5px;
       font-size: 12px;
       text-decoration: none;
    }

    .buttonsAccount .buttons .cancel svg {
        margin-right: 5px;
    }

    @media (max-width: 600px) {
        .buttonsAccount {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 5px 0;
            margin: 0 0 10px 0;
            flex-wrap: wrap;
            }

            .buttonsAccount .buttons a {
               margin: 3px 2px;
               padding: 7px 15px;
            }

            .buttonsAccount .buttons .cancel {
                margin: 3px 2px;
                padding: 7px 15px;
            }
        
    }
