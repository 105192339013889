.FilterData .btnFilter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    Background-color: var(--White);
    color: var(--Paragraph);
    font-weight: 500;
    border: 1px solid var(--Gray);
    border-radius: 6px;
    font-size: 12px;
}

.FilterData .searchItensFilter {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column nowrap;
    background: var(--BorderHover);
    border-left: 1px solid var(--Border);
    backdrop-filter: blur(4px);
    position: fixed;
    transform: translateX(0);
    top: 0;
    right: 0;
    height: 100vh;
    width: 650px;
    transition: transform 0.3s ease-in-out;
    z-index: 99;
    box-shadow: 1px 1px 20px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 1px 1px 20px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 1px 1px 20px 0px rgba(0,0,0,0.3);
}
.FilterData .searchItensFilterNone {
    transform: translateX(100%);
    flex-flow: column nowrap;
    background: var(--White);
    border-left: 1px solid var(--Border);
    backdrop-filter: blur(4px);
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 500px;
    transition: transform 0.3s ease-in-out;
    z-index: 99;
}
.FilterData .searchItensFilter .buttonsFilter {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.FilterData .searchItensFilter .buttonsFilter .btnFilter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    Background-color: var(--White);
    color: var(--Paragraph);
    font-weight: 500;
    border: 1px solid var(--Gray);
    border-radius: 6px;
    font-size: 12px;
    margin-top: -50px;
}

.FilterData .searchItensFilter .searchOptionsFilter {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.FilterData .searchItensFilterNone .searchOptionsFilter {
    width: 95%;
    display: none;
}

.FilterData .searchItensFilterNone .buttons {
    width: 95%;
    display: none;
}

.FilterData .searchItensFilter .searchOptionsFilter button svg {
    margin-right: 0px;
}


.FilterData .searchItensFilter .searchOptionsFilter .form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%
}

.FilterData .searchItensFilter .searchOptionsFilter .form input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    margin: 5px;
    padding: 10px 20px;
}
.FilterData .searchItensFilter .searchOptionsFilter .form input::placeholder {
    color: var(--Gray);
}
.FilterData .searchItensFilter .searchOptionsFilter .form select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    margin: 5px;
    padding: 10px 20px;
}
.FilterData .searchItensFilter .searchOptionsFilter .form textarea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    margin: 5px;
    padding: 10px 20px;
    resize: none;
}

.FilterData .searchItensFilter .searchOptionsFilter .form .TypeButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 5px;
    padding: 10pxx;
    width: 100%;
}

.FilterData .searchItensFilter .searchOptionsFilter .form .TypeButtons .btnType1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid var(--Aproved);
    background-color: rgba(0,168,89, 0.05);
    margin: 5px;
    padding: 20px 20px;
    color: var(--Aproved);
    font-size: 16px;
}
.FilterData .searchItensFilter .searchOptionsFilter .form .TypeButtons .btnType2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid var(--Primary);
    background-color: rgba(224, 40, 47, 0.05);
    margin: 5px;
    padding: 20px 20px;
    color: var(--Primary);
    font-size: 16px;
}
.FilterData .searchItensFilter .searchOptionsFilter .form .TypeButtons .btnType3 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    margin: 5px;
    border: 1px solid var(--Aproved);
    background-color: var(--AprovedHover);
    padding: 20px 20px;
    color: var(--White);
    font-size: 16px;
}
.FilterData .searchItensFilter .searchOptionsFilter .form .TypeButtons .btnType4 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    margin: 5px;
    border: 1px solid var(--Primary);
    background-color: var(--PrimaryHover);
    padding: 20px 20px;
    color: var(--White);
    font-size: 16px;
}
.FilterData .searchItensFilter .searchOptionsFilter .form .send {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid var(--Primary);
    background-color: var(--PrimaryHover);
    margin: 5px;
    padding: 10px 20px;
    color: var(--White);
}

.FilterData .searchItensFilter .searchOptionsFilter .form .send svg {
    margin-right: 5px;
    font-size: 20px;
}
.FilterData .searchItensFilter .searchOptionsFilter .form .btnDocuments {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    margin: 5px;
    padding: 10px 20px;
    color: var(--Gray);
}

.FilterData .searchItensFilter .searchOptionsFilter .form .btnDocuments svg {
    margin-right: 5px;
    font-size: 20px;
}
.FilterData .searchItensFilter .searchOptionsFilter .form .dataForm {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.FilterData .searchItensFilter .searchOptionsFilter .form .dataForm h4 {
    color: var(--Gray);
    margin: 5px;
}
@media(max-width:600px) {

    
    .FilterData .searchItensFilter {
        width: 100%;
        padding: 20px;
    }

    .FilterData .searchItensFilter .buttons .btnFilter {
        margin-top: 50px;
    }

    .FilterData .searchItensFilter .searchOptionsFilter .form {
        width: 100%
    }

   
    @media(max-width: 450px) { 
.FilterData .searchItensFilter .searchOptionsFilter {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .FilterData .searchItensFilter .searchOptionsFilter .form .btnDocuments {
            width: 80%;
        }
    }

}