.ToolBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 170px;
    position: fixed;
    left: 0;
    overflow:auto;
    height: 100vh;
    padding: 75px 0 100px 0;
    border: none;
    border-right: 1px solid #f1f1f1;
    background-color: var(--White);
}

.ToolBar::-webkit-scrollbar {
    width: 5px; 
    margin: 2px;              /* width of the entire scrollbar */
  }
  
  .ToolBar::-webkit-scrollbar-track {
    background: var(--BorderInput);        /* color of the tracking area */
  }
  
  .ToolBar::-webkit-scrollbar-thumb {
    background-color: var(--Border);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid var(--Border);  /* creates padding around scroll thumb */
  }



.ToolBar .image {
    width: 90px;
    height: 90px;
    border-radius: 6px;
    object-fit: cover;
    margin: 5px 0;
}
.ToolBar .image img{
    width: 90px;
    height: 90px;
    border-radius: 6px;
    object-fit: cover;
}

.ToolBar .Tools {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}
.ToolBar .Tools .ToolUnic {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 3px 5px;
    border-radius: 6px;
}
.ToolBar .Tools .ToolUnic a {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-decoration: none;
    background-color: var(--White);
    padding: 10px 20px;
    width: 100%;
    font-size: 16px;
    color: var(--Black2);
    border-radius: 20px;
}
.ToolBar .Tools .ToolUnic a:hover {
    background-color: var(--BorderInput2);
    color: var(--Paragraph);
}

.ToolBar .Tools .ToolUnic a p {
    font-size: 13px;
    font-weight: 600;
}

.ToolBar .Tools .ToolUnic a svg {
    margin: 0 10px 0 0;
}
.ToolBar .Tools .ToolUnic2 {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 3px 5px;
    border-radius: 6px;
}
.ToolBar .Tools .ToolUnic2 a {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-decoration: none;
    background-color: var(--White);
    padding: 10px 20px;
    width: 100%;
    font-size: 16px;
    color: var(--Black2);
    border-radius: 20px;
}
.ToolBar .Tools .ToolUnic2 a:hover {
    background-color: var(--BorderInput2);
    color: var(--Paragraph);
}

.ToolBar .Tools .ToolUnic2 a p {
    font-size: 13px;
    font-weight: 600;
}

.ToolBar .Tools .ToolUnic2 a svg {
    margin: 0 10px 0 0;
}
.ToolBar .Tools .ToolUnic3 {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 3px 5px;
    border-radius: 6px;
}
.ToolBar .Tools .ToolUnic3 a {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-decoration: none;
    background-color: var(--White);
    padding: 10px 20px;
    width: 100%;
    font-size: 16px;
    color: var(--Black2);
    border-radius: 20px;
}
.ToolBar .Tools .ToolUnic3 a:hover {
    background-color: var(--BorderInput2);
    color: var(--Paragraph);
}

.ToolBar .Tools .ToolUnic3 a p {
    font-size: 13px;
    font-weight: 600;
}

.ToolBar .Tools .ToolUnic3 a svg {
    margin: 0 10px 0 0;
}

.ToolBar .Tools button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    width: 90%;
    border: none;
    border-radius: 6px;
    background-color: var(--Primary);
    margin: 8px 0;
    color: var(--White);
    font-weight: 700;
}

.ToolBar .Tools button svg {
    margin-right: 5px;
}
.ToolBar .Tools .btnToolBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    width: 90%;
    border: none;
    border-radius: 6px;
    background-color: var(--Secondary);
    margin: 5px 0;
    color: var(--White);
    font-weight: 700;
}
.ToolBar .Tools .btnToolBar:hover {
    background-color: var(--SecondaryHover);
    color: var(--White);
}


@media (max-width: 700PX) {
    .ToolBar {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        height: 75px;
        position: fixed;
        bottom: 0;
        overflow:auto;
        padding: 10px 2px;
        border: none;
        border-top: 1px solid var(--Border);
        background-color: rgba(255, 255, 255 )
    }

    .ToolBar .image {
        display: none;
    }

    .ToolBar .Tools {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 50px;
    }

    .ToolBar .Tools .ToolUnic2 {
        display: none;
    }
    .ToolBar .Tools .ToolUnic {
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 3px;
        border-radius: 15px;
    }

    .ToolBar .Tools .ToolUnic a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        background-color: var(--BorderHover);
        padding: 5px;
        width: 100%;
        height: 50px;
        font-size: 16px;
        color: var(--Description);
        border-radius: 15px;
    }

    .ToolBar .Tools .ToolUnic a svg {
        margin: 0 0 3px 0;
    }
    .ToolBar .Tools .ToolUnic3 {
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 3px;
        border-radius: 15px;
    }

    .ToolBar .Tools .ToolUnic3 a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        background-color: var(--Primary);
        padding: 5px;
        width: 100%;
        height: 50px;
        font-size: 16px;
        color: var(--White);
        border-radius: 15px;
    }

    .ToolBar .Tools .ToolUnic3 a svg {
        margin: 0 0 3px 0;
    }

    .ToolBar .Tools .btnToolBar {
        display: none; 
    }

    .ToolBar .Tools button {
        display: none; 
    }
}