.dataContractsUnic{
    width: 190px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: 3px;
  }
  .dataContractsUnic h6{
    font-weight: 700;
    color: var(--Gray);
  }
  .dataContractsUnic h5{
    font-weight: 700;
    color: var(--Gray2);
  }
  
  .dataContractsUnic button{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 5px 10px;
    border: 6px;
    background-color: var(--Primary);
    color: var(--White);
    font-size: 10px;
    margin: 2px 0px;
  }
  
  .dataContractsUnic .btn{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 5px 10px;
    border: 6px;
    background-color: var(--Secondary);
    color: var(--White);
    font-size: 10px;
    margin: 2px 0px;
  }
  
  
  .dataContractsUnic .btn2{
    font-weight: 700;
    color: var(--Primary);
  }
  