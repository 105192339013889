.NotificationsAdm {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.NotificationsAdm .aside {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: 170px;
    padding-top: 80px;
}
.NotificationsAdm .aside h3 {
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
}
.NotificationsAdm .informations {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.NotificationsAdm .informations .infoData {
    width: 200px;
    height: 100px;
    background-color: var(--Border);
    border-radius: 6px;
    margin: 10px;
}

@media (max-width: 700px) {
    .NotificationsAdm .informations {
        padding-bottom: 100px;
    }
    .NotificationsAdm .aside {
        padding-left: 0px;
    }
}