.PaymentCompleted {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.PaymentCompleted .Payment {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    margin-top: 80px;
}

.PaymentCompleted .Payment .PlainSelected {
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 20px;
    margin: 10px;
}
.PaymentCompleted .Payment .PlainSelected h3 {
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.PaymentCompleted .Payment .PlainSelected h3 svg {
    color: var(--Aproved);
}
.PaymentCompleted .Payment .PlainSelected .plain {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}


.PaymentCompleted .Payment .PlainSelected .plain h4 {
    font-weight: bold;
}
.PaymentCompleted .Payment .PlainSelected .plain h4 span {
    font-weight: 900;
}

.PaymentCompleted .Payment .PlainSelected a {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--White);
    border: 1px solid var(--Primary);
    padding: 10px;
    color: var(--Primary);
    border-radius: 3px;
}

.PaymentCompleted .Payment .PlainSelected .text {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin: 5px 0;
    padding: 10px 5px;
    border: none;
    border-top: 1px solid var(--Border);
    border-bottom: 1px solid var(--Border);
}

.PaymentCompleted .Payment .PlainSelected .text p {
    font-size: 12px;
    font-weight: 700;
    margin: 2px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    color: var(--Description);
}


.PaymentCompleted .Payment .PlainSelected .text svg {
    color: var(--Aproved);
    font-size: 16px;
    margin-right: 5px;
}

.PaymentCompleted .Payment .PlainSelected .pricePlain {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.PaymentCompleted .Payment .PlainSelected .pricePlain h5{
    font-weight: 700;
    color: var(--Gray);
}
.PaymentCompleted .Payment .PlainSelected .pricePlain h4{
    font-weight: 700;
    color: var(--Paragraph);
}

.PaymentCompleted .Payment .PaymentPlayn {
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 20px;
    margin: 10px;
    color: var(--Description);
}

.PaymentCompleted .Payment .PaymentPlayn img {
    width: 200px;
    margin-bottom: 10px;
}

.PaymentCompleted .Payment .PaymentPlayn a {
    font-weight: 500;
    margin-bottom: 10px;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid var(--Secondary);
    color: var(--Secondary);
    text-decoration: none;
}
.PaymentCompleted .Payment .PaymentPlayn a:hover {
    border: 1px solid var(--Secondary);
    color: var(--White);
    background: var(--Secondary);
}
.PaymentCompleted .Payment .PaymentPlayn h3 {
    font-weight: 700;
    margin-bottom: 10px;
}
.PaymentCompleted .Payment .PaymentPlayn h4 {
    font-weight: 700;
    margin-bottom: 10px;
}
.PaymentCompleted .Payment .PaymentPlayn h5 {
    font-weight: 700;
    color: var(--Gray);
}
.PaymentCompleted .Payment .PaymentPlayn h6 {
    font-weight: 500;
    color: var(--Gray);
    font-size: 12px;
}

.PaymentCompleted .Payment .PaymentPlayn button {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    padding: 10px 20px;
    color: var(--White);
    border-radius: 20px;
    margin-top: 40px;
    border: none;
    font-size: 16px;
}

@media (max-width: 750px) {
    .PaymentCompleted .Payment {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 60px;
    }
    
    .PaymentCompleted .Payment .PlainSelected {
        width: 100%;
    }

    
.PaymentCompleted .Payment .PaymentPlayn {
    width: 100%;
}

}