
.btnControl {
    width: 30px;
    height: 30px;
    padding: 8px;
    border: none;
    border-radius: 20px;
    background-color: var(--BorderInput);
    color: var(--Paragraph);
    margin: 5px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
    .btnControl svg {
    margin: 0px;
  }
  
  .btnControl:hover {
    background-color: var(--Primary);
    color: var(--White);
  }

.FilterDataClient .viewFilter {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column nowrap;
    background: var(--BorderHover);
    border-left: 1px solid var(--Border);
    backdrop-filter: blur(4px);
    position: fixed;
    transform: translateX(0);
    top: 0;
    right: 0;
    height: 100vh;
    width: 650px;
    transition: transform 0.3s ease-in-out;
    z-index: 99;
    box-shadow: 1px 1px 20px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 1px 1px 20px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 1px 1px 20px 0px rgba(0,0,0,0.3);
}
.FilterDataClient .viewFilterNone {
    transform: translateX(100%);
    flex-flow: column nowrap;
    background: var(--White);
    border-left: 1px solid var(--Border);
    backdrop-filter: blur(4px);
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 500px;
    transition: transform 0.3s ease-in-out;
    z-index: 99;
}
.FilterDataClient .viewFilter .buttonsFilter {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.FilterDataClient .viewFilter .buttonsFilter .btnFilter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    Background-color: var(--White);
    color: var(--Paragraph);
    font-weight: 500;
    border: 1px solid var(--Gray);
    border-radius: 6px;
    font-size: 12px;
}

.FilterDataClient .viewFilter .dataClient {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.FilterDataClient .viewFilter .dataClient h3{
   color: var(--Primary);
   font-weight: 700;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
} 
.FilterDataClient .viewFilter .dataClient h4{
   color: var(--Paragraph);
   font-weight: 700;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
} 
.FilterDataClient .viewFilter .dataClient h5{
   color: var(--Paragraph);
   font-weight: 500;
    text-align: center;
    margin: 2px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
} 

.FilterDataClient .viewFilter .dataClient h5 span {
    font-weight: 700;
    margin: 5px 3px 0px 3px;
}
.FilterDataClient .viewFilter .dataClient h5 svg {
    margin: 5px 3px 0px 3px;
}


.FilterDataClient .viewFilterNone .dataClient {
    width: 95%;
    display: none;
}

.FilterDataClient .viewFilterNone .buttons {
    width: 95%;
    display: none;
}

.FilterDataClient .viewFilter .dataClient button svg {
    margin-right: 0px;
}


@media(max-width:600px) {

    
    .FilterDataClient .viewFilter {
        width: 100%;
        padding: 20px;
    }

    .FilterDataClient .viewFilter .buttons .btnFilter {
        margin-top: 50px;
    }



   
    @media(max-width: 570px) { 
        .FilterDataClient .btnAtiveFilter {
            width: 100%;
          }

    @media(max-width: 450px) { 
.FilterDataClient .viewFilter .dataClient {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

    }
    }

}