.TestingPeriodInitial {
    width: 100%;
    height: 100vh;
    background-color: var(--BorderHover);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
}

.TestingPeriodInitial h2 {
    text-align: center;
    color: var(--Primary);
    font-weight: 900;
    margin: 10px;
}
.TestingPeriodInitial img {
    width: 300px;
    margin: 20px 10px;
}

.TestingPeriodInitial .coming {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 10px;
}
.TestingPeriodInitial h4 {
    text-align: center;
    color: var(--Gray);
    font-weight: 700;
    margin-bottom: 20px;
}
.TestingPeriodInitial .coming h5 {
    text-align: center;
    color: var(--Gray);
    font-weight: 700;
    margin-bottom: 20px;
}
.TestingPeriodInitial .coming h4 {
    text-align: center;
    color: var(--Gray);
    font-weight: 700;
    margin-bottom: 20px;
}
.TestingPeriodInitial .coming h4 svg {
    margin-right: 3px;
    margin-bottom: -2px;
    margin-top: 0px;
}
.TestingPeriodInitial .logo {
    width: 150px;
    margin: 20px;
}
.TestingPeriodInitial .logo img {
    width: 100%;
}
.TestingPeriodInitial .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.TestingPeriodInitial .buttons a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-decoration: none;
    border: none;
    background-color: var(--Primary);
    padding: 10px 20px;
    border-radius: 20px;
    color: var(--White);
    margin: 5px;
    width: 200px;
    font-size: 14px;
}
.TestingPeriodInitial .buttons .update {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-decoration: none;
    border: 1px solid var(--Primary);
    background-color: var(--BorderInput2);
    padding: 10px 20px;
    border-radius: 20px;
    color: var(--Primary);
    margin: 5px;
    width: 200px;
    font-size: 14px;
}
.TestingPeriodInitial .buttons .update:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-decoration: none;
    border: 1px solid var(--Primary);
    background-color: var(--Primary);
    padding: 10px 20px;
    border-radius: 20px;
    color: var(--BorderInput2);
    margin: 5px;
    width: 200px;
    font-size: 14px;
}
.TestingPeriodInitial .buttons .logout {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-decoration: none;
    border: none;
    background-color: var(--BorderInput2);
    padding: 10px 20px;
    border-radius: 20px;
    color: var(--Primary);
    margin: 5px;
    width: 200px;
    font-size: 14px;
}


@media (max-width: 600px) {

    .TestingPeriodInitial h2 {
        font-size: 20px;
    }

    .TestingPeriodInitial img {
        width: 250px;
        margin: 20px 10px;
    }

    .TestingPeriodInitial .image {
        width: 261px;
        height: 164px;
        margin-top: 63px;
        margin-left: 1px;
    }


    .TestingPeriodInitial .coming {
        width: 200px;
    }
}