.ViewPropertyList .btnProperty {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:flex-end;
    border: none;
    background-color: var(--Primary);
    color: var(--White);
    padding: 5px;
}
.ViewPropertyList .btnProperty2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:flex-end;
    border: none;
    background-color: var(--Primary);
    color: var(--White);
    padding: 0px;
    font-size: 12px;
    margin-left: 0px;
}
.ViewPropertyList .btnProperty3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:flex-end;
    border: none;
    background-color: var(--BorderInput2);
    color: var(--Paragraph);
    padding: 6px 10px;
    font-size: 12px;
    margin-left: 0px;
}

.ViewPropertyList .btnProperty2 svg {
    font-size: 11px;
}
.ViewPropertyList .btnProperty3 svg {
    font-size: 12px;
}


.ViewPropertyList .searchItensFilter {
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column nowrap;
    background: var(--BorderHover);
    border-left: 1px solid var(--Border);
    backdrop-filter: blur(4px);
    position: fixed;
    transform: translateX(0);
    top: 0;
    right: 0;
    height: 100vh;
    width: 650px;
    transition: transform 0.3s ease-in-out;
    z-index: 99;
    box-shadow: 1px 1px 20px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 1px 1px 20px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 1px 1px 20px 0px rgba(0,0,0,0.3);
    overflow: auto;
}

.ViewPropertyList .searchItensFilter::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }
  
    .ViewPropertyList .searchItensFilter::-webkit-scrollbar-track {
    background: var(--BorderHover);        /* color of the tracking area */
    border-radius: 20px;
  }
  
    .ViewPropertyList .searchItensFilter::-webkit-scrollbar-thumb {
    background-color: var(--Border);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid var(--BorderHover);  /* creates padding around scroll thumb */
  }


.ViewPropertyList .searchItensFilterNone {
    transform: translateX(100%);
    flex-flow: column nowrap;
    background: var(--White);
    border-left: 1px solid var(--Border);
    backdrop-filter: blur(4px);
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 500px;
    transition: transform 0.3s ease-in-out;
    z-index: 99;
}
.ViewPropertyList .searchItensFilter .buttonsFilter {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.ViewPropertyList .searchItensFilter .buttonsFilter .btnFilter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    Background-color: var(--White);
    color: var(--Paragraph);
    font-weight: 500;
    border: 1px solid var(--Gray);
    border-radius: 6px;
    font-size: 12px;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.ViewPropertyList .searchItensFilterNone .searchOptionsFilter {
    width: 95%;
    display: none;
}

.ViewPropertyList .searchItensFilterNone .buttons {
    width: 95%;
    display: none;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter button svg {
    margin-right: 0px;
}


.ViewPropertyList .searchItensFilter .searchOptionsFilter .form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    color:var(--Paragraph);
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .imageClient {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 20px;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .imageClient img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 20px;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-weight: 900;
    color: var(--Primary);
    margin: 5px 0;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form h5 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-weight: 600;
    margin: 1px 0;
}
.ViewPropertyList .searchItensFilter .searchOptionsFilter .form h6 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-weight: 700;
    color: var(--Gray);
    margin: 10px 0 5px 0;
    font-size: 14px;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form svg {
    margin-right: 2px;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .buttonsContact {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    margin: 10px;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .buttonsContact button {
    border-radius: 6px;
    background-color: var(--Primary);
    color: var(--White);
    border: none;
    width: 100%;
    padding: 10px 20px;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .iconsBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 20px 0;
    padding: 10px 0;
    flex-wrap: wrap;
    border: none;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .iconsBox .iconUnicBox {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
    padding: 7px;
    margin: 5px 0;
    border-radius: 6px;
    color: var(--Paragraph);
    background-color: var(--BorderInput2);
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .iconsBox .iconUnicBox p {
    color: var(--Gray2);
    font-weight: 700;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .iconsBox .iconUnicBox p sup {
    vertical-align: top;
    font-size: 10px;
    margin-top: -7px;
}

sup {
    vertical-align: super;
    font-size: smaller;
}
.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .iconsBox .iconUnicBox svg {
    font-size: 22px;
    margin-right: 10px;
    color: var(--Paragraph);
}


.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .iconsBox .iconUnicBox .simbolBox {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .iconsBox .iconUnicBox .simbolBox svg {
    font-size: 20px;
    margin-right: 5px;
    color: var(--Description);
}


.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .iconsBox .iconUnicBox .simbolBox p {
    color: var(--Description);
    font-weight: 400;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}


.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .infosCompany {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 6px;
    border: 1px solid var(--Border);
    margin: 20px ;
    padding: 10px;
    margin-top: 20px;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .infosCompany .pricing {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: flex-start;
    margin-bottom: 5px;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .infosCompany .pricing h5 {
    color: var(--Gray);
    font-weight: 800;
    margin: 5px 10px;
}
.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .infosCompany .pricing h5 span {
    font-weight: 500;
}
.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .infosCompany .pricing h2 {
    color: var(--Paragraph);
    font-weight: 400;
    margin: 0 10px;
}
.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .infosCompany .pricing h2 span {
    color: var(--Paragraph);
    font-weight: 800;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .infosCompany .otherPrincings {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid var(--Border);
    padding: 5px 0;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .infosCompany .otherPrincings h5 {
    color: var(--Gray);
    font-weight: 700;
    margin: 5px 10px;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .infosCompany .pricingTotal {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    padding: 5px 0;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .infosCompany .pricingTotal h4 {
    color: var(--Paragraph);
    font-weight: 800;
    margin: 5px 10px;
    margin-bottom: 20px;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .documents {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}
.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .documents h5 {
    color: var(--Gray);
    margin: 15px;
    font-weight: 700;
}
.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .documents .documentUnic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    background-color: var(--BorderHover);
    width: 50%;
    padding: 5px 10px;
    margin: 3px 20px;
    border-radius: 20px;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .documents .documentUnic a {
    color: var(--SecondaryHover);
    text-decoration: none;
    font-size: 14px;
}
.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .documents .documentUnic a svg {
    margin-right: 3px;
}


.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .information {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 5px 0;
    padding: 10px;
}

.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .information h5 {
    color: var(--Gray);
    margin: 5px;
    font-weight: 700;
}
.ViewPropertyList .searchItensFilter .searchOptionsFilter .form .information h6 {
    color: var(--Paragraph);
    margin: 5px;
    font-weight: 600;
    font0size: 10px;
}

@media(max-width:600px) {

    
    .ViewPropertyList .searchItensFilter {
        width: 100%;
        padding: 20px;
    }


    .ViewPropertyList .searchItensFilter .searchOptionsFilter .form {
        width: 100%
    }

    .ViewPropertyList .searchItensFilter .searchOptionsFilter .form .iconsBox .iconUnicBox {
        width: 48%;
    }
    .ViewPropertyList .searchItensFilter .searchOptionsFilter .form .documents h5 {
        margin: 5px 15px;
    }

    .ViewPropertyList .searchItensFilter .searchOptionsFilter .form .documents .documentUnic {
        width: 100%;
    }

   
    @media(max-width: 450px) { 
.ViewPropertyList .searchItensFilter .searchOptionsFilter {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .ViewPropertyList .searchItensFilter .searchOptionsFilter .form .infosCompany {
            width: 100%;
        }
        

    }

}