.PrintInfosFinancer {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.PrintInfosFinancer .aside {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

}

.PrintInfosFinancer .aside .textHome {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
  }
  
  
  .PrintInfosFinancer .aside .textHome h3 {
      font-weight: bold;
      text-align: center;
      color: var(--Paragraph);
  }
  .PrintInfosFinancer .aside .textHome img{
      width: 100px;
  }
.PrintInfosFinancer .infosFinancer {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
}
.PrintInfosFinancer .infosFinancer .infoFinancerUnic {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 5px;
    padding: 10px 15px;
    background-color: var(--BorderHover);
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}
.PrintInfosFinancer .infosFinancer .infoFinancerUnicTotal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 5px;
    padding: 10px 15px;
    background-color: var(--AprovedHover);
    border-radius: 6px;
    color: var(--White);
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.PrintInfosFinancer .infosFinancer .infoFinancerUnicTotal2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 5px;
    padding: 10px 15px;
    background-color: var(--Primary);
    border-radius: 6px;
    color: var(--White);
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.PrintInfosFinancer .infosFinancer .infoFinancerUnic .top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.PrintInfosFinancer .infosFinancer .infoFinancerUnicTotal .top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.PrintInfosFinancer .infosFinancer .infoFinancerUnicTotal2 .top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.PrintInfosFinancer .infosFinancer .infoFinancerUnic .top h5 {
    color: var(--Gray);
    font-weight: 700;
}
.PrintInfosFinancer .infosFinancer .infoFinancerUnicTotal .top h5 {
    color: var(--White);
    font-weight: 700;
}
.PrintInfosFinancer .infosFinancer .infoFinancerUnicTotal2 .top h5 {
    color: var(--White);
    font-weight: 700;
}
.PrintInfosFinancer .infosFinancer .infoFinancerUnic h2 {
    color: var(--Paragraph);
    font-weight: 500;
    margin-top: 5px;
}
.PrintInfosFinancer .infosFinancer .infoFinancerUnicTotal h2 {
    color: var(--White);
    font-weight: 500;
    margin-top: 5px;
}
.PrintInfosFinancer .infosFinancer .infoFinancerUnicTotal2 h2 {
    color: var(--White);
    font-weight: 500;
    margin-top: 5px;
}
.PrintInfosFinancer .top h5 {
    color: var(--Gray);
    font-weight: 700;
}
.PrintInfosFinancer .financerList {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15px;
    background-color: var(--White);
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
    margin: 10px 10px 100px 10px;
    border-radius: 10px;

}
.PrintInfosFinancer .financerList .searchFinance {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    padding: 5px 0px;
}

.PrintInfosFinancer .financerList .searchFinance input {
    border: 1px solid var(--Border);
    padding: 5px 10px;
    border-radius: 6px;
    height: 36px;
    width: 100%;
    margin: 3px;
    color: var(--Description);
}
.PrintInfosFinancer .financerList .searchFinance input::placeholder {
    color: var(--Gray);
}
.PrintInfosFinancer .financerList .searchFinance select {
border: 1px solid var(--Border);
padding: 5px 10px;
border-radius: 6px;
height: 36px;
width: 100%;
margin: 3px;
color: var(--Description);
}
.PrintInfosFinancer .financerList .FinancerListUnic {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
    padding:10px;
    background-color: var(--BorderHover);
    border-radius: 6px;
    color: var(--Gray);
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}

.PrintInfosFinancer .financerList .FinancerListUnic h5 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.PrintInfosFinancer .financerList .FinancerListUnic .up {
    color: var(--Aproved);
    justify-content: center;
}
.PrintInfosFinancer .financerList .FinancerListUnic .down {
    color: var(--Primary);
    justify-content: center;
}
.PrintInfosFinancer .financerList .FinancerListUnic .date {
    justify-content: center;
}
.PrintInfosFinancer .financerList .FinancerListUnic  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    padding: 5px 10px;
    background-color: var(--BorderHover);
    border-radius: 6px;
    color: var(--Gray);
}
.PrintInfosFinancer .financerList .FinancerListUnic .buttons  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--BorderInput2);
    border: none;
    color: var(--Gray);
    padding: 7px;
    border-radius: 6px;
    margin: 3px;
}

.PrintInfosFinancer .financerList .FinancerListUnic .buttons button svg {
    margin: 0px;
}







@media (max-width: 990px) {
    .PrintInfosFinancer .financerList {
        width: 90%;
    }
    .PrintInfosFinancer .infosFinancer {
        width: 90%;
    }

@media (max-width: 700px) {
    .PrintInfosFinancer .aside {
        padding-left: 0px;
        padding-bottom: 80px;
    }


    
    @media (max-width: 550px) {


        .PrintInfosFinancer .financerList {
            width: 95%;
        }

        .PrintInfosFinancer .infosFinancer {
            width: 95%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px;
        }

        

        .PrintInfosFinancer .infosFinancer .infoFinancerUnic {
            margin: 5px 20px;
            padding: 5px 20px;
        }

        .PrintInfosFinancer .infosFinancer .infoFinancerUnicTotal {
            margin: 5px 20px;
            padding: 5px 20px;
        }

        .PrintInfosFinancer .financerList .searchFinance {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 5px 0;
            padding: 5px 0px;
        }

        .PrintInfosFinancer .financerList .FinancerListUnic  {
            flex-wrap: wrap;
            margin: 5px 0;
            padding: 5px;
        }
        .PrintInfosFinancer .financerList .FinancerListUnic h5 {
            width: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 2px 0;
        }

        .PrintInfosFinancer .financerList .FinancerListUnic .up {
            color: var(--Aproved);
            justify-content: center;
            margin: 2px 0;
        }
        .PrintInfosFinancer .financerList .FinancerListUnic .down {
            color: var(--Primary);
            justify-content: center;
            margin: 2px 0;
        }
        .PrintInfosFinancer .financerList .FinancerListUnic .date {
            justify-content: center;
            margin: 2px 0;
        }
        .PrintInfosFinancer .financerList .FinancerListUnic .buttons {
            width: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 2px 0;
            margin: 0;
            padding: 0px 10px;
            border-radius: 6px;
            color: var(--Gray);
        }


        
}
}
}