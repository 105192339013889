

.btnControl {
    width: 30px;
    height: 30px;
    padding: 8px;
    border: none;
    border-radius: 20px;
    background-color: var(--BorderInput);
    color: var(--Gray2);
    margin: 5px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .btnControl svg {
    margin: 0px;
  }
  
  .btnControl:hover {
    background-color: var(--Primary);
    color: var(--White);
  }
  

  .react-modal-content .content-modal-home-Search .itensModalHome-Search .form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%
}

.react-modal-content .content-modal-home-Search .itensModalHome-Search .form input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    margin: 5px;
    padding: 10px 20px;
}
.react-modal-content .content-modal-home-Search .itensModalHome-Search .form input::placeholder {
    color: var(--Gray);
}
.react-modal-content .content-modal-home-Search .itensModalHome-Search .form select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    margin: 5px;
    padding: 10px 20px;
}
.react-modal-content .content-modal-home-Search .itensModalHome-Search .form textarea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    margin: 5px;
    padding: 10px 20px;
    resize: none;
}

.react-modal-content .content-modal-home-Search .itensModalHome-Search .form .TypeButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 5px;
    padding: 10pxx;
    width: 100%;
    flex-wrap: wrap;
}

.react-modal-content .content-modal-home-Search .itensModalHome-Search .form .TypeButtons .btnType1Select {
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid var(--Aproved);
    background-color: rgba(0,168,89, 0.05);
    margin: 5px;
    padding: 20px 20px;
    color: var(--Aproved);
    font-size: 12px;
}
.react-modal-content .content-modal-home-Search .itensModalHome-Search .form .TypeButtons .btnType2Select {
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid var(--Edit);
    background-color: rgba(255,194,0, 0.05);
    margin: 5px;
    padding: 20px 20px;
    color: var(--EditHover);
    font-size: 12px;
}
.react-modal-content .content-modal-home-Search .itensModalHome-Search .form .TypeButtons .btnType3Select {
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    margin: 5px;
    border: 1px solid var(--Aproved);
    background-color: var(--AprovedHover);
    padding: 20px 20px;
    color: var(--White);
    font-size: 12px;
}
.react-modal-content .content-modal-home-Search .itensModalHome-Search .form .TypeButtons .btnType4Select {
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    margin: 5px;
    border: 1px solid var(--Edit);
    background-color: var(--EditHover);
    padding: 20px 20px;
    color: var(--White);
    font-size: 12px;
}
.react-modal-content .content-modal-home-Search .itensModalHome-Search .form .TypeButtons .btnType5Select {
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    margin: 5px;
    border: 1px solid var(--Primary);
    background-color: rgba(224,40,47, 0.05);
    padding: 20px 20px;
    color: var(--Primary);
    font-size: 12px;
}
.react-modal-content .content-modal-home-Search .itensModalHome-Search .form .TypeButtons .btnType6Select {
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    margin: 5px;
    border: 1px solid var(--Primary);
    background-color: var(--PrimaryHover);
    padding: 20px 20px;
    color: var(--White);
    font-size: 12px;
}

.react-modal-content .content-modal-home-Search .itensModalHome-Search .form .TypeButtons .btnType8Select {
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid var(--Secondary);
    background-color: rgba(0,175,239, 0.05);
    margin: 5px;
    padding: 20px 20px;
    color: var(--Secondary);
    font-size: 12px;
}
.react-modal-content .content-modal-home-Search .itensModalHome-Search .form .TypeButtons .btnType7Select {
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    margin: 5px;
    border: 1px solid var(--Secondary);
    background-color: var(--SecondaryHover);
    padding: 20px 20px;
    color: var(--White);
    font-size: 12px;
}
.react-modal-content .content-modal-home-Search .itensModalHome-Search .form .TypeButtons .btnType9Select {
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    margin: 5px;
    border: 1px solid #9370DB;
    background-color: rgba(75,0,130, 0.05);
    padding: 20px 20px;
    color: #4B0082;
    font-size: 12px;
}
.react-modal-content .content-modal-home-Search .itensModalHome-Search .form .TypeButtons .btnType10Select {
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    margin: 5px;
    border: 1px solid ##9370DB;
    background-color: #4B0082;
    padding: 20px 20px;
    color: var(--White);
    font-size: 12px;
}

.react-modal-content .content-modal-home-Search .itensModalHome-Search .form .send {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid var(--Primary);
    background-color: var(--PrimaryHover);
    margin: 5px;
    padding: 10px 20px;
    color: var(--White);
}

.react-modal-content .content-modal-home-Search .itensModalHome-Search .form .send svg {
    margin-right: 5px;
    font-size: 20px;
}
.react-modal-content .content-modal-home-Search .itensModalHome-Search .form .btnDocuments {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid var(--Secondary);
    background-color: var(--SecondaryHover);
    margin: 5px;
    padding: 10px 20px;
    color: var(--White);
}

.react-modal-content .content-modal-home-Search .itensModalHome-Search .form .btnDocuments svg {
    margin-right: 5px;
    font-size: 20px;
}
.react-modal-content .content-modal-home-Search .itensModalHome-Search .form .dataForm {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.react-modal-content .content-modal-home-Search .itensModalHome-Search .form .dataForm h4 {
    color: var(--Gray);
    margin: 5px;
}

@media (max-width: 700px) {
    .react-modal-content .content-modal-home-Search .itensModalHome-Search .form {
        width: 100%
    }
    @media (max-width: 570px) {

    @media (max-width: 575px) {
        .react-modal-content .content-modal-home-Search .itensModalHome-Search .form .btnDocuments {
            width: 80%;
        }



        .react-modal-content .content-modal-home-Search .itensModalHome-Search .form .TypeButtons .btnType1Select {
            width: 100%;
        }
        .react-modal-content .content-modal-home-Search .itensModalHome-Search .form .TypeButtons .btnType2Select {
            width: 100%;
        }
        .react-modal-content .content-modal-home-Search .itensModalHome-Search .form .TypeButtons .btnType3Select {
            width: 100%;
        }
        .react-modal-content .content-modal-home-Search .itensModalHome-Search .form .TypeButtons .btnType4Select {
            width: 100%;
        }
        .react-modal-content .content-modal-home-Search .itensModalHome-Search .form .TypeButtons .btnType5Select {
            width: 100%;
        }
        .react-modal-content .content-modal-home-Search .itensModalHome-Search .form .TypeButtons .btnType6Select {
            width: 100%;
        }
        
}
}
}