.MyAssessments {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.MyAssessments .aside {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 170px;
  padding-top: 100px;
}

.MyAssessments .aside .textHome {
width: 90%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
margin-bottom: 20px;
}


.MyAssessments .aside .textHome h3 {
  font-weight: bold;
  text-align: center;
  color: var(--Paragraph);
}

.MyAssessments .aside .infoData {
width: 90%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
flex-wrap: wrap;
padding: 5px;
background-color: var(--White);
border: 1px solid var(--Border);
border-radius: 20px;
}

.MyAssessments .aside .infoData .textInfo{
padding: 10px 20px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
border: 1px solid var(--Border);
border-radius: 20px;
margin: 5px;

}

.MyAssessments .aside .infoData .textInfo h5 {
font-weight: 600;
text-align: center;
color: var(--Gray);
}
.MyAssessments .aside .infoData .textInfo h5 span {
font-weight: 900;
color: var(--Primary);
}

.MyAssessments .aside .link {
border: none;
border-radius: 20px;
background-color: none;
color: var(--Primary);
font-weight: 600;
text-decoration: none;
}


.MyAssessments .informations {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:5px;
  background-color: var(--White);
  border: 1px solid var(--Border);
  border-radius: 20px;
  margin-bottom: 100px;
}
.MyAssessments .titleShedulign {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding:5px;
}
.MyAssessments .titleShedulign h3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 10px 20px;
  background-color: var(--Secondary);
  border-radius: 20px;
  color: var(--White);
  gont-weight: 700;
}
.MyAssessments .titleShedulign2 {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding:5px;
}
.MyAssessments .titleShedulign2 h3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 10px 20px;
  background-color: var(--Aproved);
  border-radius: 20px;
  color: var(--White);
  gont-weight: 700;
}
.MyAssessments .titleShedulign3 {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding:5px;
}
.MyAssessments .titleShedulign3 h3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 10px 20px;
  background-color: var(--Primary);
  border-radius: 20px;
  color: var(--White);
  gont-weight: 700;
}

.MyAssessments .informations .AssessmentsListAdm{
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  border-bottom: 1px solid var(--Border);
  margin-top: 5px;
}
.MyAssessments .informations .AssessmentsListAdm .image{
  width: 100px;
  height: 95%;
  object-fit: cover;
  border-radius: 20px;
}
.MyAssessments .informations .AssessmentsListAdm .image img{
  width: 100px;
  height: 95%;
  object-fit: cover;
  border-radius: 20px;
}

.MyAssessments .informations .AssessmentsListAdm .textAssessmentsListAdm{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 5px 10px;
  text-align:left;
  width: 100%;
  height: 100%;
}
.MyAssessments .informations .AssessmentsListAdm .user{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 5px 10px;
  text-align:left;
  width: 100%;
}
.MyAssessments .informations .AssessmentsListAdm .user .aproved{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 5px 10px;
  background-color: var(--Aproved);
  border-radius: 20px;
  color: var(--White);
  gont-weight: 700;
}

.MyAssessments .informations .AssessmentsListAdm .user .cancel{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 5px 10px;
  background-color: var(--Aproved);
  border-radius: 20px;
  color: var(--White);
  gont-weight: 700;
}
.MyAssessments .informations .AssessmentsListAdm .user .pending{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 5px 10px;
  background-color: var(--Edit);
  border-radius: 20px;
  color: var(--White);
  gont-weight: 700;
}
.MyAssessments .informations .AssessmentsListAdm .user .conclused{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 5px 10px;
  background-color: var(--Secondary);
  border-radius: 20px;
  color: var(--White);
  gont-weight: 700;
}

.MyAssessments .informations .AssessmentsListAdm .textAssessmentsListAdm .AssessmentsView {
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-direction: row;
width: 100%;
padding:5px;
flex-wrap:  wrap;
}

.MyAssessments .informations .AssessmentsListAdm .textAssessmentsListAdm .textDataAssessmentsListAdm{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  text-align:left;
  width: 100%;
}
.MyAssessments .informations .AssessmentsListAdm .textAssessmentsListAdm .textDataAssessmentsListAdm a{
 text-decoration: none;
}


.MyAssessments .informations .AssessmentsListAdm .textAssessmentsListAdm .textDataAssessmentsListAdm h4 {
  color: var(--Primary);
  font-weight: 700;
  margin: 0 10px;
  text-align:left;
}

.MyAssessments .informations .AssessmentsListAdm .textAssessmentsListAdm .textDataAssessmentsListAdm h5 {
  color: var(--Gray);
  margin: 2px 9px;
  text-align:left;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  font-weight: 500;
}

.MyAssessments .informations .AssessmentsListAdm .textAssessmentsListAdm .textDataAssessmentsListAdm h5 svg {
  margin-right: 2px;
}
.MyAssessments .informations .AssessmentsListAdm .textAssessmentsListAdm .textDataAssessmentsListAdm h6 {
  color: var(--Gray);
  margin: 5px 10px 0 10px;
  text-align:left;
}
.MyAssessments .informations .AssessmentsListAdm .textAssessmentsListAdm .emphasis {
  margin: 5px;
  text-align:center;
  padding: 5px 10px;
  background-color: var(--BorderInput);
  color: var(--Paragraph);
  border-radius: 20px;
  font-size: 12px;
}
.MyAssessments .informations .AssessmentsListAdm .textAssessmentsListAdm .status1 {
  margin: 5px;
  text-align:center;
  padding: 5px 10px;
  background-color: var(--Aproved);
  color: var(--White);
  border-radius: 20px;
  font-size: 12px;
}
.MyAssessments .informations .AssessmentsListAdm .textAssessmentsListAdm .status2 {
  margin: 5px 10px 0 10px;
  text-align:center;
  padding: 5px 10px;
  background-color: var(--Secondary);
  color: var(--White);
  border-radius: 20px;
  font-size: 12px;
}
.MyAssessments .informations .AssessmentsListAdm .textAssessmentsListAdm .status3 {
  margin: 5px 10px 0 10px;
  text-align:center;
  padding: 5px 10px;
  background-color: var(--Edit);
  color: var(--White);
  border-radius: 20px;
  font-size: 12px;
}
.MyAssessments .informations .AssessmentsListAdm .textAssessmentsListAdm .status4 {
  margin: 5px 10px 0 10px;
  text-align:center;
  padding: 5px 10px;
  background-color: var(--Primary);
  color: var(--White);
  border-radius: 20px;
  font-size: 12px;
}
.MyAssessments .informations .AssessmentsListAdm .infosContactData {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 2px 0;
  width: 100%;
  flex-wrap: wrap;
}
.MyAssessments .informations .AssessmentsListAdm .infosContactData .infoUnicData {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 5px;
  margin: 5px;
  border-radius: 20px;
  border: 1px solid var(--Border);
}

.MyAssessments .informations .AssessmentsListAdm .infosContactData .infoUnicData svg {
  color: var(--Gray);
  margin-right: 5px;
  font-size: 12px;
}
.MyAssessments .informations .AssessmentsListAdm .infosContactData .infoUnicData  h6 {
  font-weight: 600;
  color: var(--Gray);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

.MyAssessments .informations .AssessmentsListAdm .infosContactData .infoUnicData:hover {
  background-color: var(--BorderInput2);
}

.MyAssessments .informations .AssessmentsListAdm .buttons{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  border-left: 1px solid var(--Border);
}

.MyAssessments .informations .AssessmentsListAdm .buttons .btnControl {
width: 30px;
height: 30px;
padding: 8px;
border: none;
border-radius: 20px;
background-color: var(--BorderInput);
color: var(--Gray2);
margin: 5px;
font-weight: 700;
              display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
}
.MyAssessments .informations .AssessmentsListAdm .buttons .btnControl svg {
margin: 0px;
}

.MyAssessments .informations .AssessmentsListAdm .buttons .btnControl:hover {
background-color: var(--Primary);
color: var(--White);
}

.MyAssessments .aside .search {
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
margin: 0px 10px 20px 10px;
width: 90%;
padding: 10px;
background-color: var(--White);
border: 1px solid var(--Border);
border-radius: 30px;
}
.MyAssessments .aside .search .selection {
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
width: 100%;
}


.MyAssessments .aside .search input {
width: 50%;
height: 40px;
padding: 10px 20px;
border: 1px solid var(--Border);
border-radius: 20px;
margin: 2px;
}
.MyAssessments .aside .search .selection select {
width: 100%;
height: 40px;
padding: 10px 20px;
border: 1px solid var(--Border);
border-radius: 20px;
margin: 2px;
}


@media(max-width: 850px) {
.MyAssessments .aside .search {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
}


.MyAssessments .aside .search input {
  width: 100%;
}


@media (max-width: 700px) {
  .MyAssessments .informations {
      padding: 0 10px 10px 10px;
  }
  .MyAssessments .aside {
      padding-left: 0px;
  }



  @media (max-width: 570px) {

        .MyAssessments .informations .AssessmentsListAdm{
          width: 100%;
          margin:20px 5px;

          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
      }

      .MyAssessments .informations .AssessmentsListAdm .textAssessmentsListAdm .textDataAssessmentsListAdm h4 {
        margin:5px 10px;
      }

          .MyAssessments .informations .AssessmentsListAdm .image{
          width: 100%;
          height: 100px;
      }
        .MyAssessments .informations .AssessmentsListAdm .image img{
          width: 100%;
          height: 100px;
      }

      .MyAssessments .informations .AssessmentsListAdm .infosContactData .infoUnicData {
        padding: 5px;
        margin: 5px 2px
      }
    

      .MyAssessments .informations .AssessmentsListAdm .buttons{
        justify-content: space-evenly;
        flex-direction: row;
        width: 100%;
        border-left:none;
        border-top: 1px solid var(--Border);
        padding: 5px;
    }

      .MyAssessments .informations .AssessmentsListAdm .buttons .linkEdit {
      width: 100%;
      heigth: 100%
    }
      .MyAssessments .informations .AssessmentsListAdm .buttons .newDocument {
      width: 100%;
      heigth: 100%
    }
      .MyAssessments .informations .AssessmentsListAdm .buttons .delete {
      width: 100%;
      heigth: 100%
    }


    .MyAssessments .aside .search {
      width: 90%;
      padding: 10px;
    }

    .MyAssessments .aside .search .selection {
      flex-direction: column;
      width: 100%;
    }

    .MyAssessments .aside .infoData .textInfo {
      padding: 5px 10px;
      margin: 20px;
    }
    .MyAssessments .aside .infoData .textInfo h5 {
      font-size: 12px;
    }
    
  }
}
}
